video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.contact-container {
  /*background-color: #d1981f;*/
  background-color: #bfc2c1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 130vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding: 5%;
}
.contact-container > h1 {
  color: #fff;
  font-size: 60px;
  margin-top: 5vh;
}
.contact-container > h3 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.contact-container > a {
  margin-top: 8px;
  color: #fff;
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.contact-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.contact-text-container {

  background-color: #bfc2c1;
  color: #fff;
  height: 75%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  object-fit: contain;
  padding: 16px;
  margin-left: 10%;
  margin-right: 10%;
}
.contact-text-container > img{
  height: 50%;
  width: 100%;
  border: 2px solid;
  border-color: #fff;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.contact-btns {
  margin-top: 32px;
}

.contact-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.social-icon-contactlink {
  color: #fff;
  font-size: 40px;
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : portrait) {
  .contact-container {
    height: 1500px;
  }
  .contact-container > h1 {
    font-size: 70px;
    margin-top: 0px;
    height: 220px;
  }
  .contact-text-container {
    width: 70%;
    height: 80%;
  }
  .contact-text-container > img{
    height: 100%;
    width: 100%;
}
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape) {
  .contact-container {
    height: 160vh;
  }
  .contact-container > h1 {
    font-size: 70px;
    margin-top: 0px;
    height: 200px;
  }
  .contact-text-container {
    width: 70%;
    height: 85%;
  }
  .contact-text-container > img{
    height: 60%;
    width: 100%;
}
}

@media screen and (min-device-width: 426px) and (max-device-width: 767px) and (orientation : portrait) {
  .contact-container {
    height: 1500px;
  }
  .contact-container > h1 {
    font-size: 70px;
    margin-top: 0px;
    height: 220px;
  }
  .contact-text-container {
    width: 70%;
    height: 80%;
  }
  .contact-text-container > img{
    height: 100%;
    width: 100%;
}
}

@media screen and (min-device-width: 426px) and (max-device-width: 767px) and (orientation : landscape) {
  .contact-container {
    height: 120vh;
  }
  .contact-container > h1 {
    font-size: 70px;
    margin-top: 0px;
    height: 200px;
  }
  .contact-text-container > img{
    height: 50%;
    width: 100%;
}
  .contact-text-container {
    width: 70%;
    height: 80%;
}
}

@media screen and (max-device-width: 425px) and (orientation : portrait){

  .contact-container {
    height: 1100px;
  }
  .contact-container > h1 {
    font-size: 50px;
    margin-top: 0px;
  }
  .contact-container > p {
    font-size: 30px;
  }
  .contact-text-container {
    width: 80%;
    height: 90%;
  }
  .contact-text-container > img{
    height: 30%;
    width: 90%;
}
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-device-width: 425px) and (orientation : landscape){

  .contact-container {
    height: 300vh;
  }
  .contact-container > h1 {
    font-size: 50px;
    margin-top: 0px;
  }
  .contact-container > p {
    font-size: 15px;
  }
  .contact-text-container {
    width: 70%;
    height: 90%;
  }
  .contact-text-container > img{
    height: 30%;
    width: 90%;
}
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
