video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.values-container {
  /* background: url('/images/Image14.jpeg') center center/cover no-repeat; */
  background-color: #30818e;
  height: 200vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}
.values-container > h1 {
  color: #fff;
  font-size: 60px;
  margin-top: 0px;
}
.values-text-container > h3 {
  color: #fff;
  font-size: 30px;

}
.values-text-container {

  background-color: #30818e;
  height: 80%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  object-fit: contain;
  padding: 16px;
  margin-left: 10%;
  margin-right: 10%;
}
.values-text-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.values-text-container > img{
  height: 40%;
  width: 100%;
  border: 2px solid;
  border-color: #fff;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.values-btns {
  margin-top: 10px;
}

.values-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
@media screen and (max-device-width: 768px) and (max-device-height: 1024px) and (orientation : portrait) {
  .values-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .values-text-container > h3 {
    font-size: 30px;

  }
  .values-container {
    height: 200vh;
  }
  .values-text-container {
    height: 75%;
    width: 80%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .values-text-container > img {
    height: 30%;
    width: 80%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .values-text-container > p {
    font-size: 15px;
}
}
@media screen and (max-device-height: 768px) and (max-device-width: 1024px) and (orientation : landscape) {
  .values-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .values-text-container > h3 {
    font-size: 30px;

  }
  .values-container {
    height: 300vh;
  }
  .values-text-container {
    height: 80%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .values-text-container > img {
    height: 20%;
    width: 60%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .values-text-container > p {
    font-size: 15px;
}
}
@media screen and (max-device-height: 426px) and (max-device-width: 767px) and (orientation : landscape) {
  .values-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .values-container {
    height: 200vh;
  }
  .values-text-container {
    height: 80%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .values-text-container > p {
    font-size: 15px;
}
.values-text-container > h3 {
  font-size: 30px;
}
.values-text-container > img {
  height: 20%;
  width: 60%;
  margin-left: 5%;
  margin-right: 5%;
}
}
@media screen and (max-device-width: 426px) and (max-device-height: 767px) and (orientation : portrait) {
  .values-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .values-container {
    height: 250vh;
  }
  .values-text-container {
    height: 80%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .values-text-container > p {
    font-size: 15px;
}
  .values-text-container > h3 {
    font-size: 30px;

}
}

@media screen and (max-device-width: 425px) and (orientation : portrait) {
  .values-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .values-container  {
    height: 250vh;
    font-size: 50px;
    margin-top: 0px;
  }

  .values-container > p {
    font-size: 15px;
  }
  .values-text-container {
    height: 70%;
    width: 70%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .values-text-container > img{
    height: 15%;
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
  .values-text-container > h3 {
    font-size: 30px;

  }
}

@media screen and (max-device-width: 425px) and (orientation : landscape){
  .values-container > {
    height: 250vh;
  }
  .values-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .values-container > h3 {
    font-size: 30px;
  }
  .values-text-container {
    height: 80%;
    width: 80%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .values-text-container > img{
    height: 20%;
    width: 60%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .values-text-container > p {
    font-size: 15px;
}
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
