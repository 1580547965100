video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.secmodel-container {
  /* background: url('/images/Image13.jpeg') center center/cover no-repeat; */
  background-color: #bfc2c1;
  height: 150vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding: 16px;
  margin-left: 0%;
  margin-right: 0%;
}

.secmodel-container > h1 {
  color: white;
  font-size: 60px;
  margin-top: 5vh;
}

.secmodel-project-container > h3 {
  color: black;
  font-size: 4  0px;

}
.secmodel-project-container {

  background-color: #fff;
  height: 60%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  object-fit: contain;
  padding: 16px;
  margin-left: 0%;
  margin-right: 0%;
}
.secmodel-project-container > p {
  margin-top: 8px;
  color: black;
  font-size: 15px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.secmodel-project-container > img {
  height: 50%;
  width: 50%;
  border: 1px solid black;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.secmodel-btns {
  margin-top: 32px;
}

.secmodel-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : portrait) {
  .secmodel-container {
    height: 160vh;
  }
  .secmodel-container > h1 {
    font-size: 30px;
    margin-top: -150px;
  }
  .secmodel-project-container {
    width: 80%;
    height: 70%;
  }
  .secmodel-project-container > img {
    height: 30%;
    width: 80%;
    border: 1px solid black;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape) {
  .secmodel-container {
    height: 280vh;
  }
  .secmodel-container > h1 {
    font-size: 30px;
    margin-top: -100px;
  }
  .secmodel-project-container {
    width: 80%;
    height: 70%;
  }
  .secmodel-project-container > img {
    height: 40%;
    width: 80%;
    border: 1px solid black;

  }
}

@media screen and (min-device-width: 426px) and (max-device-width: 767px) and (orientation : portrait) {
  .secmodel-container {
    height: 180vh;
  }
  .secmodel-container > h1 {
    font-size: 30px;
    margin-top: -150px;
  }
  .secmodel-project-container {
    width: 80%;
    height: 80%;
  }
  .secmodel-project-container > img {
    height: 40%;
    width: 80%;
    border: 1px solid black;

  }
}

@media screen and (min-device-width: 426px) and (max-device-width: 767px) and (orientation : landscape) {
  .secmodel-container {
    height: 150vh;
  }
  .secmodel-container > h1 {
    font-size: 30px;
    margin-top: -150px;
  }
  .secmodel-project-container {
    width: 80%;
    height: 80%;
  }
  .secmodel-project-container > img {
    height: 40%;
    width: 80%;
    border: 1px solid black;
  }
}

@media screen and (max-width: 425px) and (orientation : portrait){
  .secmodel-container {
    height: 150vh;
  }
  .secmodel-container > h1 {
    font-size: 30px;
    margin-top: -100px;
  }
  .secmodel-container > p {
    font-size: 15px;
  }
  .secmodel-project-container {
    width: 80%;
    height: 80%;
  }
  .secmodel-project-container > img {
    height: 30%;
    width: 90%;
    border: 1px solid black;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 425px) and (orientation : landscape){
  .secmodel-container {
    height: 200vh;
  }
  .secmodel-container > h1 {
    font-size: 30px;
    margin-top: -100px;
  }
  .secmodel-container > p {
    font-size: 15px;
  }
  .secmodel-project-container {
    width: 80%;
    height: 80%;
  }
  .secmodel-project-container > img {
    height: 30%;
    width: 90%;
    border: 1px solid black;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}
