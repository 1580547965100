video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.about-container {
  /* background: url('/images/Image15.jpeg') center center/cover no-repeat; */
  /*background-color: #d1981f;*/
  background-color: #394b66;
  height: 150vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.about-container > h1 {
  color: #fff;
  font-size: 60px;
  margin-top: 5vh;
}
.about-container > h3 {
  color: #fff;
  font-size: 30px;
  margin-top: -100px;
}

.about-container > p {
  margin-top: 8px;
  color: black;
  font-size: 25px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}


.about-text-container > h3 {
  color: #fff;
  font-size: 4  0px;

}
.about-text-container {

  background-color: #394b66;
  height: 75%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding: 16px;
  margin-left: 10%;
  margin-right: 10%;
}
.about-text-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 30px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.about-adrian-container > {
  height: 230vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-adrian-container > h3 {
  color: #fff;
  font-size: 4  0px;

}
.about-adrian-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 30px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.about-adrian-container > img{
  height: 55%;
  width: 35%;
  border: 2px solid;
  border-color: #fff;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.about-btns {
  margin-top: 32px;
}

.about-btns .btn {
  margin: 6px;

}

.fa-play-circle {
  margin-left: 4px;
}




/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : portrait) {
  .about-container {
    height: 200vh;
  }
  .about-container > h1 {
    font-size: 50px;
    /* margin-top: -150px; */
  }
  .about-text-container {
    height: 80%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .about-adrian-container {
    padding-left: 0%;
    padding-right: 0%;
  }
  .about-adrian-container > img{
    height: 50%;
    width: 40%;
    display: block;
    justify-content: center;
    align-items: center;
    margin-left: 30%;
    margin-right: 30%;

  }
  .about-container > p {
    font-size: 20px;
  }
  .about-text-container > p {
    font-size: 20px;
  }
  .about-adrian-container > p {
    font-size: 20px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape) {
  .about-container {
    height: 200vh;
  }
  .about-container > h1 {
    font-size: 50px;
    /* margin-top: -150px; */
  }
  .about-text-container {
    height: 80%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .about-adrian-container {
    width: 100%;
  }
  .about-adrian-container > img{
    height: 65%;
    width: 35%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .about-container > p {
    font-size: 20px;
  }
  .about-text-container > p {
    font-size: 20px;
  }
  .about-adrian-container > p {
    font-size: 20px;
  }
}

@media screen and (min-device-width: 426px) and (max-device-width: 767px) and (orientation : portrait) {
  .about-container {
    height: 220vh;
  }
  .about-container > h1 {
    font-size: 50px;
    /* margin-top: -150px; */
  }
  .about-text-container {
    height: 80%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .about-adrian-container {
    padding-left: 10%;
    padding-right: 10%;
  }
  .about-adrian-container > img{
    height: 70%;
    width: 30%;
    display: block;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
    margin-right: 20%;

  }
  .about-container > p {
    font-size: 15px;
  }
  .about-text-container > p {
    font-size: 15px;
  }
  .about-adrian-container > p {
    font-size: 15px;
  }
}

@media screen and (min-device-width: 426px) and (max-device-width: 767px) and (orientation : landscape) {
  .about-container {
    height: 180vh;
  }
  .about-container > h1 {
    font-size: 50px;
    /* margin-top: -150px; */
  }
  .about-text-container {
    height: 80%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .about-adrian-container {
    width: 100%;
  }
  .about-adrian-container > img{
    height: 40%;
    width: 25%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .about-container > p {
    font-size: 15px;
  }
  .about-text-container > p {
    font-size: 15px;
  }
  .about-adrian-container > p {
    font-size: 15px;
  }
}

@media screen and (max-device-width: 425px) and (orientation : portrait){
  .about-container {
    height: 120vh;
  }
  .about-container > h1 {
    font-size: 50px;
    /* margin-top: -100px; */
  }

  .about-container > p {
    font-size: 15px;
  }
  .about-text-container > p {
    font-size: 15px;
  }
  .about-adrian-container > p {
    font-size: 15px;
  }

  .about-text-container {
    height: 80%;
    width: 70%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .about-adrian-container {
    height: 90%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .about-adrian-container > img{
    height: 40%;
    width: 60%;
    border: 2px solid;
    border-color: #fff;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
    align-items: center;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-device-width: 425px) and (orientation : landscape){
  .about-container {
    height: 400vh;
  }
  .about-container > h1 {
    font-size: 50px;
    /* margin-top: -100px; */
  }

  .about-container > p {
    font-size: 15px;
  }
  .about-text-container > p {
    font-size: 15px;
  }
  .about-adrian-container > p {
    font-size: 15px;
  }

  .about-text-container {
    height: 75%;
    width: 90%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .about-adrian-container {
    height: 90%;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .about-adrian-container > img{
    height: 30%;
    width: 50%;
    border: 2px solid;
    border-color: #fff;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
    align-items: center;
  }

  .btn {
    width: 100%;
  }
}
