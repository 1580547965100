video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.project-container {
  background-color: #bfc2c1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.project-container > h1 {
  color: #fff;
  font-size: 60px;
  margin-top: 0px;
}

.project-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.project-btns {
  margin-top: 32px;
}

.project-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.cards {

  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 10 10px;
  width: 800px;
}

.cards__items {
  margin-bottom: 0px;
  margin-left: 60px;
  margin-right: 60px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  border: 5px solid;
  border-color: #fff;
  /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px; */
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

/* .cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
} */

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}
@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}


@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : portrait) {
  .project-container > h1 {
    font-size: 70px;
    margin-top: 60px;
  }
  .project-container {
    height: 260vh;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape) {
  .project-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
  .project-container {
    height: 150vh;

  }
}

@media screen and (min-device-width: 426px) and (max-device-width: 767px) and (orientation : portrait) {
  .project-container > h1 {
    font-size: 70px;
    margin-top: 60px;
  }
  .project-container {
    height: 260vh;
  }
}

@media screen and (min-device-width: 426px) and (max-device-width: 767px) and (orientation : landscape) {
  .project-container > h1 {
    font-size: 70px;
    margin-top: 60px;
  }
  .project-container {
    height: 260vh;
  }
}

@media screen and (max-device-width: 425px) and (orientation : portrait){
  .project-container > h1 {
    font-size: 50px;
    margin-top: 50px;
  }

  .project-container > p {
    font-size: 30px;
  }
  .project-container {
    height: 230vh;
  }
  .cards__wrapper {
    position: relative;
    margin: 50px 10 10px;
    width: 500px;
  }
  .cards__items {
    width: 400px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-device-width: 425px) and (orientation : landscape){
  .project-container > h1 {
    font-size: 50px;
    margin-top: 50px;
  }

  .project-container > p {
    font-size: 15px;
  }
  .project-container {
    height: 400vh;
  }
  .cards__wrapper {
    position: relative;
    margin: 50px 10 10px;
    width: 500px;
  }
  .cards__items {
    width: 400px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }

  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }

  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
