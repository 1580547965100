video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.ethics-container {
  /* background: url('/images/Image4.jpeg') center center/cover no-repeat; */
  background-color: #394b66;
  height: 260vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding: 16px;
  margin-left: 0%;
  margin-right: 0%;
}

.ethics-container > h1 {
  color: #fff;
  font-size: 60px;
  margin-top: 0px;
}
.ethics-container > img{
  height: 80%;
  width: 100%;
  border: 2px solid;
  border-color: #fff;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.ethics-article-container > h3 {
  color: black;
  font-size: 4  0px;

}
.ethics-article-container {

  background-color: #fff;
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  object-fit: contain;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-left: 0%;
  margin-right: 0%;
}
.ethics-article-container > p {
  margin-top: 8px;
  color: black;
  font-size: 15px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.ethics-btns {
  margin-top: 32px;
}

.ethics-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : portrait) {

  .ethics-container {
    height: 500vh;
  }
  .ethics-container > h1 {
    font-size: 60px;
    margin-top: 0px;
  }
  .ethics-container > img{
    height: 10%;
    width: 80%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .ethics-article-container {
    background-color: #fff;
    height: 70%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    object-fit: contain;
    padding: 16px;
    margin-left: 0%;
    margin-right: 0%;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape) {

  .ethics-container {
    height: 400vh;
  }
  .ethics-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
  .ethics-container > img{
    height: 15%;
    width: 80%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .ethics-article-container {
    background-color: #fff;
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    object-fit: contain;
    padding: 16px;
    margin-left: 0%;
    margin-right: 0%;
  }
}


@media screen and (min-device-width: 426px) and (max-width: 767px) and (orientation : portrait) {
  .ethics-container {
    height: 600vh;
  }
  .ethics-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .ethics-container > p {
    font-size: 30px;
  }
  .ethics-container > img{
    height: 3%;
    width: 80%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .ethics-article-container {
    background-color: #fff;
    height: 90%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    object-fit: contain;
    padding: 16px;
    margin-left: 0%;
    margin-right: 0%;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
@media screen and (min-device-width: 426px) and (max-width: 767px) and (orientation : landscape){
  .ethics-container {
    height: 800vh;
  }
  .ethics-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .ethics-container > p {
    font-size: 15px;
  }
  .ethics-container > img{
    height: 5%;
    width: 60%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .ethics-article-container {
    height: 85%;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-device-width: 425px) and (orientation : portrait) {
  .ethics-container {
    height: 680vh;
  }
  .ethics-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .ethics-container > p {
    font-size: 30px;
  }
  .ethics-container > img{
    height: 3%;
    width: 80%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .ethics-article-container {
    background-color: #fff;
    height: 90%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    object-fit: contain;
    padding: 16px;
    margin-left: 0%;
    margin-right: 0%;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
@media screen and (max-device-width: 425px) and (orientation : landscape){
  .ethics-container {
    height: 950vh;
  }
  .ethics-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .ethics-container > p {
    font-size: 15px;
  }
  .ethics-container > img{
    height: 5%;
    width: 60%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .ethics-article-container {
    height: 85%;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
